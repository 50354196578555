<template>
  <div class="links-container">
    <NuxtLink to="/publisher">
      <div class="links-container__item">
        <div class="links-container__item-text">Сотрудничество</div>
        <div class="links-container__item-icon">
          <i class="fi fi-bs-arrow-up-right"></i>
        </div>
      </div>
    </NuxtLink>

    <NuxtLink to="/publisher">
      <div class="links-container__item">
        <div class="links-container__item-text">Агенствам</div>
        <div class="links-container__item-icon">
          <i class="fi fi-bs-arrow-up-right"></i>
        </div>
      </div>
    </NuxtLink>

    <NuxtLink to="/publisher">
      <div class="links-container__item">
        <div class="links-container__item-text">Паблишерам</div>
        <div class="links-container__item-icon">
          <i class="fi fi-bs-arrow-up-right"></i>
        </div>
      </div>
    </NuxtLink>

    <NuxtLink to="/moderation/ ">
      <div class="links-container__item">
        <div class="links-container__item-text">Модерация</div>
        <div class="links-container__item-icon">
          <i class="fi fi-bs-arrow-up-right"></i>
        </div>
      </div>
    </NuxtLink>
  </div>
</template>
<script setup>
const route = useRoute();

</script>
<style scoped lang="scss">
.links-container {
  @apply bottom-5 absolute flex flex-col w-full px-5 mx-auto justify-center items-center;

  &__item {
    @apply bg-[#F0F9FC] w-full rounded-2xl flex flex-row justify-center gap-2 items-center py-2 align-middle mb-1 cursor-pointer;

    &-text {
      @apply text-[13px] text-[#00AEE8] font-[400] leading-[26px] cursor-pointer;
    }
    &-icon {
      @apply text-[#00AEE8] text-[10px] font-bold pt-[4px] cursor-pointer;
    }
  }
}
</style>
